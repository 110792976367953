<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import {
  attributesToRetrieve,
  restrictSearchableAttributes,
} from '@domains/Carsharing/Bookings/config';
import { useBookingList } from '@domains/Carsharing/Bookings/composables/bookingList.composable';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'UserBookings',
  components: {
    MuiAlgoliaList,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { contentCells, facets, exportColumns } = useBookingList();
    return { contentCells, facets, exportColumns };
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.csBookingsStartDesc;
    this.attributesToRetrieve = attributesToRetrieve;
    this.restrictSearchableAttributes = restrictSearchableAttributes;
    this.algoliaFilters = `user_uuid:${this.userUuid}`;
  },
};
</script>
<template>
  <div
    data-test-id="user_bookings"
    class="UserBookings"
  >
    <h1 class="mb-2 emobg-font-weight-semibold">
      Bookings
    </h1>
    <MuiAlgoliaList
      :index="algoliaIndex"
      :filters="algoliaFilters"
      :facets="facets"
      :table-config="contentCells"
      :export-columns="exportColumns"
      :query-parameters="{ attributesToRetrieve, restrictSearchableAttributes }"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
